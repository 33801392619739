import React, { useState, useEffect } from "react";
import "./VoiceBotiFrame.css";
import { getAuth, getIdToken } from "firebase/auth";

const VoiceBotIframe = ({ onIdylateChange }) => {
  const [iframeSrc, setIframeSrc] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const [Idylate, setIdylate] = useState(0); // State to store the value from iframe
  const [lap, setLap] = useState(0);
  const [work, setWork] = useState(0);
  const phonemes = [
    "—", "Sh", "F", "K", "Ch", "V", "B", "Z", "S", "H", 
    "N", "P", "D", "T", "M", "θ", "G", "ð", "Ʒ", "uː", "R", 
    "ʤ", "iː", "ŋ", "ɑː", "ɪ", "ʊ", "əʊ", "L", "ɒ", "ə", 
    "ɛ", "ʌ"
  ];
  useEffect(() => {
    // Fetch Firebase token and set iframe source
    const auth = getAuth();
    if (auth.currentUser) {
      getIdToken(auth.currentUser)
        .then((firebaseAuthToken) => {
          const serverEndpoint = `https://voicebot.ispeakwell.ca/get-html?authToken=${firebaseAuthToken}`;
          setIframeSrc(serverEndpoint);
        })
        .catch((error) => {
          console.error("Error getting ID token:", error);
        });
    }

    // Listen for messages from the iframe
    const handleMessage = (event) => {
      // Ensure the message comes from a trusted source
      if (event.origin === "https://voicebot.ispeakwell.ca") {
        if (event.data.type === "Idylate") {
          setIdylate(event.data.value); // Update state with the received value
        }
        if (event.data.type === "Lap") {         
          setLap(event.data.value); // Update state with the received value
        }
      }
    };

    // Add event listener to receive messages
    window.addEventListener("message", handleMessage);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []); // Empty array ensures this effect runs only once when the component mounts

  useEffect(() => {
    if(work > lap){
      var iframeWindow = document.getElementById("theBotTools").contentWindow;
      iframeWindow.postMessage(
        work,
        "https://voicebot.ispeakwell.ca/"
      );
    } else{
      setWork(lap);
    }
  },[work, lap]);

  // Whenever Idylate changes, inform the parent component by calling the prop function
  useEffect(() => {
    if (onIdylateChange) {
      onIdylateChange(Idylate); // Pass the Idylate value to the parent
    }
  }, [Idylate, onIdylateChange]);  // Effect runs whenever Idylate changes

  return (
    <div id="wrap">
      <div id="VoiceBotSetting">
        <iframe
          id="theBot"
          src={iframeSrc}
          title="VoiceBot"
          allow="microphone"
          onMouseEnter={() => setShowTooltip(false)}
          onMouseLeave={() => setShowTooltip(false)}
        ></iframe>
        <div className={`tooltip ${showTooltip ? "show-tooltip" : ""}`}>
          <span className="static-text">Phoneme:</span>
          <span className="dynamic-text">{phonemes[Idylate]}</span>
          <span className="static-text"> Work:</span>
          <span className="dynamic-text">{work}</span>
        </div>
      </div>
    </div>
  );
};

export default VoiceBotIframe;
