import React, { useState, useEffect } from "react";
import html2canvas from "html2canvas"; // Import html2canvas
import "./TwoVCombos.css";

// Define your phoneme list
const phonemes = [
  "—", "Sh", "F", "K", "Ch", "V", "B", "Z", "S", "H", 
  "N", "P", "D", "T", "M", "θ", "G", "ð", "Ʒ", "uː", "R", 
  "ʤ", "iː", "ŋ", "ɑː", "ɪ", "ʊ", "əʊ", "L", "ɒ", "ə", 
  "ɛ", "ʌ"
];

const TwoVCombos = ({ videoId='', visemePairs, clearVisemePairs }) => {
  const [glowCell, setGlowCell] = useState(null);
  const gridSize = 33; // The grid size for your table (you can adjust this)
  
  // Initialize a 2D grid to hold counts for each (previous, current) pair
  const grid = Array(gridSize).fill().map(() => Array(gridSize).fill(0));

  // Iterate through the pairs and update the grid
  visemePairs.forEach(([prev, current]) => {
    grid[prev][current] += 1;
  });

  useEffect(() => {
    // If videoId changes, ensure it’s correctly used
    console.log("Updated videoId:", videoId); // Log the updated videoId
  }, [videoId]); // Run when videoId changes

  useEffect(() => {
    if (visemePairs.length > 0) {
      const lastPair = visemePairs[visemePairs.length - 1];
      setGlowCell(lastPair);  // Trigger the glow for the last added pair
      setTimeout(() => {
        setGlowCell(null);  // Remove the glow after some time
      }, 1000); // Glow duration
    }
  }, [visemePairs]);

  const calculateAverage = () => {
    const totalCount = grid.reduce((sum, row) => sum + row.reduce((rowSum, cell) => rowSum + cell, 0), 0);
    const totalCells = gridSize * gridSize;
    const average = 3 * (totalCells > 0 ? totalCount / totalCells : 1); // Ensure average isn't zero
    console.log(average);
    return average;
  };
  
  const average = calculateAverage();  // Get dynamic average

  // Function to calculate text color based on background brightness
  const getTextColor = (bgColor) => {
    if (bgColor === 'transparent') {
      return '#000'; // Default to black if transparent
    }

    const rgb = bgColor.match(/\d+/g); // Extract RGB values
    const r = parseInt(rgb[0]);
    const g = parseInt(rgb[1]);
    const b = parseInt(rgb[2]);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000; // Luminance formula
    
    if (brightness > 200) {
      return '#333'; // Dark text for light backgrounds
    } else {
      return '#FFF5A1'; // Light yellow text for dark backgrounds
    }
  };

  // Function to save the screenshot
  const applyCellBackgroundColors = () => {
    const table = document.querySelector(".viseme-table");
    const cells = table.querySelectorAll("td");
  
    // Get the parent container background color
    const containerBgColor = 'rgba(38, 38, 39, 0.952)'; // Container background color as defined
  
    // Apply the container background to the table itself (if needed)
    table.style.backgroundColor = containerBgColor;
  
    

    // Loop through all table cells and apply the background color based on the count
    cells.forEach(cell => {
      const count = parseInt(cell.textContent); // Get the count value from the cell (it is the value displayed in the cell)
  
      // Apply background color for each cell based on the formula
      const backgroundColor = count > 0 
        ? `rgba(255, 255, 255, ${Math.min(count / average, 1)})`
        : 'transparent';
  
      // Set the background color of the cell
      cell.style.backgroundColor = backgroundColor;
    });
  };
  
  const saveScreenshot = () => {
    // Check if videoId is available before proceeding
    if (!videoId) {
      console.error("Video ID is undefined or invalid");
      return; // Exit early if videoId is not valid
    }
    // Apply the background colors manually before capturing the screenshot
    applyCellBackgroundColors();
  
    // Now, capture the screenshot
    setTimeout(() => {
      const table = document.querySelector(".viseme-table");
  
      html2canvas(table, {
        useCORS: true,
        backgroundColor: null,  // Ensure transparency in the background
        logging: true,
        scale: window.devicePixelRatio,
      }).then((canvas) => {
        const imageUrl = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = imageUrl;
        link.download = videoId + ".png";
        link.click();
      });
    }, 200); // Optional delay for ensuring rendering is complete
  };
 

  return (
    <div className="table-container">
      <button className="saveVisemeImage" onClick={saveScreenshot}>Save Image</button> {/* Add the save button */}
      
      <table className="viseme-table">
        <colgroup>
          {Array.from({ length: gridSize }).map((_, index) => (
            <col key={index} style={{ width: "40px" }} />
          ))}
        </colgroup>
        <thead>
          <tr>
            <th></th>
            {Array.from({ length: gridSize }, (_, i) => (
              <th key={i}>{phonemes[i]}</th> 
            ))}
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: gridSize }, (_, rowIdx) => (
            <tr key={rowIdx}>
              <th>{phonemes[rowIdx]}</th>  {/* Replace numbers with phonemes */}
              {Array.from({ length: gridSize }, (_, colIdx) => {
                const count = grid[rowIdx][colIdx];
                const isGlowing = glowCell && glowCell[0] === rowIdx && glowCell[1] === colIdx;

                // Calculate background color and text color
                const backgroundColor = count > 0 ? `rgba(255, 255, 255, ${Math.min(count / average, 1)})` : 'transparent';

                const textColor = getTextColor(backgroundColor); // Get text color dynamically

                return (
                  <td
                    key={colIdx}
                    className={isGlowing ? 'glow' : ''}
                    style={{
                      backgroundColor: backgroundColor,
                      color: textColor,  // Apply dynamic text color
                    }}
                  >
                    {count > 0 && count}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TwoVCombos;
