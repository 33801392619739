import React, { useState, useEffect, useCallback } from "react";
import { ReactP5Wrapper } from "react-p5-wrapper";
import "./Sociogram.css";

const Sociogram = ({ visemePairs }) => {
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [hoveredNodeId, setHoveredNodeId] = useState(null);
  const [edgeCount, setEdgeCount] = useState(50); // Default edge count (50)

  const getColorForEdge = useCallback((index) => {
    const colorCount = 1000;
    const hue = (index * 360) / colorCount; // This will evenly distribute the hue across the spectrum
    return hsvToRgb(hue, 100, 100); // Convert HSV to RGB
  }, []);

  const hsvToRgb = (h, s, v) => {
    let r, g, b;
    let i, f, p, q, t;
    s /= 100;
    v /= 100;
    i = Math.floor(h / 60);
    f = h / 60 - i;
    p = v * (1 - s);
    q = v * (1 - f * s);
    t = v * (1 - (1 - f) * s);
    if (i === 0) {
      r = v;
      g = t;
      b = p;
    } else if (i === 1) {
      r = q;
      g = v;
      b = p;
    } else if (i === 2) {
      r = p;
      g = v;
      b = t;
    } else if (i === 3) {
      r = p;
      g = q;
      b = v;
    } else if (i === 4) {
      r = t;
      g = p;
      b = v;
    } else {
      r = v;
      g = p;
      b = q;
    }
    return {
      r: Math.round(r * 255),
      g: Math.round(g * 255),
      b: Math.round(b * 255),
    };
  };

  useEffect(() => {
    const phonemes = [
      "—", "Sh", "F", "K", "Ch", "V", "B", "Z", "S", "H",
      "N", "P", "D", "T", "M", "θ", "G", "ð", "Ʒ", "uː", "R",
      "ʤ", "iː", "ŋ", "ɑː", "ɪ", "ʊ", "əʊ", "L", "ɒ", "ə",
      "ɛ", "ʌ"
    ];

    // Set up nodes (phonemes) and edges (interactions between phonemes)
    const newNodes = phonemes.map((phoneme, idx) => ({
      id: idx,
      label: phoneme,
      x: Math.cos((2 * Math.PI * idx) / phonemes.length) * 200 + 300,  // Circle arrangement
      y: Math.sin((2 * Math.PI * idx) / phonemes.length) * 200 + 300,  // Circle arrangement
    }));

    // Limit edges based on the edgeCount (from slider)
    const newEdges = visemePairs.slice(-edgeCount).map(([prev, current], index) => ({
      from: prev,
      to: current,
      color: getColorForEdge(index), // Get color based on the index
    }));

    setNodes(newNodes);
    setEdges(newEdges);
  }, [visemePairs, edgeCount, getColorForEdge]);

  const sketch = (p) => {
    p.setup = () => {
      p.createCanvas(600, 600);
      p.noLoop(); // Prevent continuous redraw
    };

    p.draw = () => {
      p.clear();

      let hoveredNode = null;
      nodes.forEach((node) => {
        const distance = p.dist(p.mouseX, p.mouseY, node.x, node.y);
        if (distance < 15) { // Radius of the node circle
          hoveredNode = node;
        }
      });

      if (hoveredNode) {
        setHoveredNodeId(hoveredNode.id);
      } else if (hoveredNodeId !== null) {
        setHoveredNodeId(null);
      }

      edges.forEach((edge) => {
        const fromNode = nodes.find((node) => node.id === edge.from);
        const toNode = nodes.find((node) => node.id === edge.to);
        if (fromNode && toNode) {
          const isHoveredEdge = hoveredNode && (hoveredNode.id === edge.from || hoveredNode.id === edge.to);
          const edgeBrightness = isHoveredEdge ? 255 : 100;

          const midX = (fromNode.x + toNode.x) / 2;
          const midY = (fromNode.y + toNode.y) / 2;

          const controlPointX = midX + (toNode.y - fromNode.y) / 2;
          const controlPointY = midY - (toNode.x - fromNode.x) / 2;

          const { r, g, b } = edge.color; // Get the RGB values for the edge color

          p.noFill();
          p.stroke(r, g, b, edgeBrightness); // Use the RGB values for the edge
          p.strokeWeight(1);

          p.bezier(fromNode.x, fromNode.y, controlPointX, controlPointY, controlPointX, controlPointY, toNode.x, toNode.y);
        }
      });

      nodes.forEach((node) => {
        p.fill(127, 15, 200);
        p.noStroke();
        p.ellipse(node.x, node.y, 30, 30);
        p.fill(255);
        p.textAlign(p.CENTER, p.CENTER);
        p.text(node.label, node.x, node.y);
      });
    };

    p.mouseMoved = () => {
      p.redraw();
    };

    p.mouseOut = () => {
      setHoveredNodeId(null);
    };
  };

  const handleSliderChange = (e) => {
    setEdgeCount(Number(e.target.value)); // Update edge count based on slider
  };

  return (
    <div className="sociogram-container">
      <div className="sociogram-wrapper">
        <ReactP5Wrapper sketch={sketch} /> {/* Render the P5.js sketch */}
      </div>
      
      {/* Slider Component */}
      <div className="slider-container">
        <input
          type="range"
          min="1"
          max="5000"
          value={edgeCount}
          onChange={handleSliderChange}
          className="vertical-slider"
        />
      </div>
    </div>
  );
};

export default Sociogram;
