import React, { useState } from "react";
import axios from "axios";
import "./GoogleSearch.css";

// Component for performing the Google Custom Search
const GoogleSearch = () => {
  const [query, setQuery] = useState("");  // Search query state
  const [results, setResults] = useState([]);  // State to hold search results
  const [loading, setLoading] = useState(false);  // Loading state
  const [error, setError] = useState(null);  // Error handling
  const apiKey = process.env.REACT_APP_GOOGLE_CLOUD_API_KEY1;
  const cx = process.env.REACT_APP_GOOGLE_SEARCH_ID;

  // Function to handle search requests
  const handleSearch = async (e) => {
    e.preventDefault();
    if (!query) return;  // Don't search if query is empty

    setLoading(true);
    setError(null);
    
    try {
      // Perform the search request
      const response = await axios.get("https://www.googleapis.com/customsearch/v1", {
        params: {
          key: apiKey,
          cx: cx,
          q: query,
        },
      });
      
      // Set the results to state
      setResults(response.data.items);
    } catch (err) {
      setError("An error occurred while fetching search results.");
      console.log("Error: ", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="google-search-container">
      <h2 className="google-search-title">Google Search</h2>
      <form onSubmit={handleSearch} className="google-search-form">
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Search..."
          className="google-search-input"
        />
        <button type="submit" disabled={loading} className="google-search-button">
          {loading ? "Searching..." : "Search"}
        </button>
      </form>

      {error && <p className="google-search-error">{error}</p>}

      <div className="google-search-results">
        {results.length > 0 && (
          <ul className="google-search-results-list">
            {results.map((result, index) => (
              <li key={index} className="google-search-result-item">
                <a
                  href={result.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="google-search-result-link"
                >
                  {result.title}
                </a>
                <p className="google-search-result-snippet">{result.snippet}</p>

                {/* Check for image data and display the image if available */}
                {result.pagemap && result.pagemap.cse_image && result.pagemap.cse_image[0].src && (
                  <img
                    src={result.pagemap.cse_image[0].src}
                    alt={result.title}
                    className="google-search-result-image"
                  />
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default GoogleSearch;
