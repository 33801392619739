import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./PrivacyPolicy.css";
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { logout } from "../utils/firebase";
import { getAuth } from "firebase/auth";
import { db } from "../utils/firebase";
import {
  getDoc,
  doc,
} from "firebase/firestore";

function PrivacyPolicy() {
  const [tokens, setTokens] = useState(0);
  useEffect(() => {
    const fetchTokens = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const userRef = doc(db, "users", user.uid);
        try {
          const userDoc = await getDoc(userRef);
          if (userDoc.exists()) {
            setTokens(userDoc.data().tokens || 0);
          }
        } catch (error) {
          console.error("Error fetching token count: ", error);
        }
      }
    };

    fetchTokens();
  }, [tokens]);

  const list = () => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItemButton onClick={Purchase}>
          <ListItemText primary={`Tokens: ${tokens}`} />
        </ListItemButton>
        <ListItemButton onClick={Dashboard}>
          <ListItemText primary="Resume Revisor" />
        </ListItemButton>
        <ListItemButton onClick={InterViewPractice}>
          <ListItemText primary="Interview Practice" />
        </ListItemButton>
        <ListItemButton onClick={ConversationPractice}>
          <ListItemText primary="Conversation Practice" />
        </ListItemButton>
        <ListItemButton onClick={iSpeakWell}>
          <ListItemText primary="iSpeakWell" />
        </ListItemButton>
        <ListItemButton onClick={admin}>
          <ListItemText primary="Admin" />
        </ListItemButton>
        <ListItemButton onClick={Logout}>
          <ListItemText primary="Logout" />
        </ListItemButton>
        <ListItemButton onClick={TermsofService}>
          <ListItemText primary="Terms of Service" />
        </ListItemButton>
      </List>
    </div>
  );



  const navigate = useNavigate();

  const navigateTo = (path) => {
    navigate(path);
  };
  const [isOpen, setIsOpen] = React.useState(false);
  
  const Dashboard = () => {
    navigate("/resumerevisor");
  };

  const InterViewPractice = () => {
    // Navigate to the InterviewPractice page
    navigate("/interview-practice");
  };

  const ConversationPractice = () => {
    navigate("/conversationPractice");
  };

  const iSpeakWell = () => {
    navigate("/voicebot");
  };
 
  const admin = () => {
    navigate("/admin");
  };

  const Purchase = () => {
    navigate("/purchase");
  };

  const Logout = async () => {
    await logout();
    navigate("/login");
  };

  const TermsofService = () => {
    navigate("/termsofservice");
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpen(open);
  };

  return (
    <div className="PRV-Container">
      <h1 className="PRV-Header">iSpeakWell Privacy policy</h1>
      <p className="PRV-Paragraph">
        We respect your privacy and are committed to protecting it through our
        compliance with this privacy policy ("Policy"). This Policy describes
        the types of information we may collect from you or that you may provide
        ("Personal Information") on the{" "}
        <a className="PRV-LINK" target="_blank" rel="noreferrer" href="https://ispeakwell.ca">
          ispeakwell.ca
        </a>{" "}
        website ("Website" or "Service") and any of its related products and
        services (collectively, "Services"), and our practices for collecting,
        using, maintaining, protecting, and disclosing that Personal
        Information. It also describes the choices available to you regarding
        our use of your Personal Information and how you can access and update
        it.
      </p>
      <p className="PRV-Paragraph">
        This Policy is a legally binding agreement between you ("User", "you" or
        "your") and this Website operator ("Operator", "we", "us" or "our"). If
        you are entering into this agreement on behalf of a business or other
        legal entity, you represent that you have the authority to bind such
        entity to this agreement, in which case the terms "User", "you" or
        "your" shall refer to such entity. If you do not have such authority, or
        if you do not agree with the terms of this agreement, you must not
        accept this agreement and may not access and use the Website and
        Services. By accessing and using the Website and Services, you
        acknowledge that you have read, understood, and agree to be bound by the
        terms of this Policy. This Policy does not apply to the practices of
        companies that we do not own or control, or to individuals that we do
        not employ or manage.
      </p>
      <h3 className="PRV-ParaHeader">Collection of personal information</h3>
      <p className="PRV-Paragraph">
        You can access and use the Website and Services without telling us who
        you are or revealing any information by which someone could identify you
        as a specific, identifiable individual. If, however, you wish to use
        some of the features offered on the Website, you may be asked to provide
        certain Personal Information (for example, your name and e-mail
        address).
      </p>
      <p className="PRV-Paragraph">
        We receive and store any information you knowingly provide to us when
        you fill any forms on the Website. When required, this information may
        include geolocation data of your device (such as latitude and
        longitude).
      </p>
      <p className="PRV-Paragraph">
        You can choose not to provide us with your Personal Information, but
        then you may not be able to take advantage of some of the features on
        the Website. Users who are uncertain about what information is mandatory
        are welcome to contact us.
      </p>
      <h3 className="PRV-ParaHeader">Privacy of children</h3>
      <p className="PRV-Paragraph">
        We do not knowingly collect any Personal Information from children under
        the age of 18. If you are under the age of 18, please do not submit any
        Personal Information through the Website and Services. If you have
        reason to believe that a child under the age of 18 has provided Personal
        Information to us through the Website and Services, please contact us to
        request that we delete that child’s Personal Information from our
        Services.
      </p>
      <p className="PRV-Paragraph">
        We encourage parents and legal guardians to monitor their children’s
        Internet usage and to help enforce this Policy by instructing their
        children never to provide Personal Information through the Website and
        Services without their permission. We also ask that all parents and
        legal guardians overseeing the care of children take the necessary
        precautions to ensure that their children are instructed to never give
        out Personal Information when online without their permission.
      </p>
      <h3 className="PRV-ParaHeader">
        Use and processing of collected information
      </h3>
      <p className="PRV-Paragraph">
        We act as a data controller and a data processor when handling Personal
        Information, unless we have entered into a data processing agreement
        with you in which case you would be the data controller and we would be
        the data processor.
      </p>
      <p className="PRV-Paragraph">
        {" "}
        Our role may also differ depending on the specific situation involving
        Personal Information. We act in the capacity of a data controller when
        we ask you to submit your Personal Information that is necessary to
        ensure your access and use of the Website and Services. In such
        instances, we are a data controller because we determine the purposes
        and means of the processing of Personal Information.
      </p>
      <p className="PRV-Paragraph">
        We act in the capacity of a data processor in situations when you submit
        Personal Information through the Website and Services. We do not own,
        control, or make decisions about the submitted Personal Information, and
        such Personal Information is processed only in accordance with your
        instructions. In such instances, the User providing Personal Information
        acts as a data controller.
      </p>
      <p className="PRV-Paragraph">
        In order to make the Website and Services available to you, or to meet a
        legal obligation, we may need to collect and use certain Personal
        Information. If you do not provide the information that we request, we
        may not be able to provide you with the requested products or services.
        Any of the information we collect from you may be used for the following
        purposes:
      </p>
      <ul className="PRV-UnorderedList">
        <li className="PRV-ListItem">Improve user experience</li>
        <li className="PRV-ListItem">
          Run and operate the Website and Services
        </li>
      </ul>
      <p className="PRV-Paragraph">
        Processing your Personal Information depends on how you interact with
        the Website and Services, where you are located in the world and if one
        of the following applies: (i) you have given your consent for one or
        more specific purposes; (ii) provision of information is necessary for
        the performance of an agreement with you and/or for any pre-contractual
        obligations thereof; (iii) processing is necessary for compliance with a
        legal obligation to which you are subject; (iv) processing is related to
        a task that is carried out in the public interest or in the exercise of
        official authority vested in us; (v) processing is necessary for the
        purposes of the legitimate interests pursued by us or by a third party.
      </p>
      <p className="PRV-Paragraph">
        {" "}
        Note that under some legislations we may be allowed to process
        information until you object to such processing by opting out, without
        having to rely on consent or any other of the legal bases. In any case,
        we will be happy to clarify the specific legal basis that applies to the
        processing, and in particular whether the provision of Personal
        Information is a statutory or contractual requirement, or a requirement
        necessary to enter into a contract.
      </p>
      <h3 className="PRV-ParaHeader">Disclosure of information</h3>
      <p className="PRV-Paragraph">
        To maintain the highest level of privacy and to protect your Personal
        Information to the full extent, we do not share your Personal
        Information with any third parties.
      </p>
      <p className="PRV-Paragraph">
        However, we may also disclose any Personal Information we collect, use
        or receive if required or permitted by law, such as to comply with a
        subpoena or similar legal process, and when we believe in good faith
        that disclosure is necessary to protect our rights, protect your safety
        or the safety of others, investigate fraud, or respond to a government
        request.
      </p>
      <h3 className="PRV-ParaHeader">Retention of information</h3>
      <p className="PRV-Paragraph">
        We will retain and use your Personal Information for the period
        necessary to comply with our legal obligations, to enforce our
        agreements, resolve disputes, and unless a longer retention period is
        required or permitted by law.
      </p>
      <p className="PRV-Paragraph">
        We may use any aggregated data derived from or incorporating your
        Personal Information after you update or delete it, but not in a manner
        that would identify you personally. Once the retention period expires,
        Personal Information shall be deleted. Therefore, the right to access,
        the right to erasure, the right to rectification, and the right to data
        portability cannot be enforced after the expiration of the retention
        period.
      </p>
      <h3 className="PRV-ParaHeader">Do Not Track signals</h3>
      <p className="PRV-Paragraph">
        Some browsers incorporate a Do Not Track feature that signals to
        websites you visit that you do not want to have your online activity
        tracked. Tracking is not the same as using or collecting information in
        connection with a website. For these purposes, tracking refers to
        collecting personally identifiable information from consumers who use or
        visit a website or online service as they move across different websites
        over time. How browsers communicate the Do Not Track signal is not yet
        uniform. As a result, the Website and Services are not yet set up to
        interpret or respond to Do Not Track signals communicated by your
        browser. Even so, as described in more detail throughout this Policy, we
        limit our use and collection of your Personal Information.
      </p>
      <h3 className="PRV-ParaHeader">Links to other resources</h3>
      <p className="PRV-Paragraph">
        The Website and Services contain links to other resources that are not
        owned or controlled by us. Please be aware that we are not responsible
        for the privacy practices of such other resources or third parties. We
        encourage you to be aware when you leave the Website and Services and to
        read the privacy statements of each and every resource that may collect
        Personal Information.
      </p>
      <h3 className="PRV-ParaHeader">Information security</h3>
      <p className="PRV-Paragraph">
        No data transmission over the Internet or wireless network can be
        guaranteed. Therefore, while we strive to protect your Personal
        Information, you acknowledge that (i) there are security and privacy
        limitations of the Internet which are beyond our control; (ii) the
        security, integrity, and privacy of any and all information and data
        exchanged between you and the Website and Services cannot be guaranteed;
        and (iii) any such information and data may be viewed or tampered with
        in transit by a third party, despite best efforts.
      </p>
      <h3 className="PRV-ParaHeader">Data breach</h3>
      <p className="PRV-Paragraph">
        In the event we become aware that the security of the Website and
        Services has been compromised or Users’ Personal Information has been
        disclosed to unrelated third parties as a result of external activity,
        including, but not limited to, security attacks or fraud, we reserve the
        right to take reasonably appropriate measures, including, but not
        limited to, investigation and reporting, as well as notification to and
        cooperation with law enforcement authorities. In the event of a data
        breach, we will make reasonable efforts to notify affected individuals
        if we believe that there is a reasonable risk of harm to the User as a
        result of the breach or if notice is otherwise required by law. When we
        do, we will send you an email.
      </p>
      <h3 className="PRV-ParaHeader">Changes and amendments</h3>
      <p className="PRV-Paragraph">
        We reserve the right to modify this Policy or its terms related to the
        Website and Services at any time at our discretion. When we do, we will
        send you an email to notify you. We may also provide notice to you in
        other ways at our discretion, such as through the contact information
        you have provided.
      </p>
      <p className="PRV-Paragraph">
        An updated version of this Policy will be effective immediately upon the
        posting of the revised Policy unless otherwise specified. Your continued
        use of the Website and Services after the effective date of the revised
        Policy (or such other act specified at that time) will constitute your
        consent to those changes. However, we will not, without your consent,
        use your Personal Information in a manner materially different than what
        was stated at the time your Personal Information was collected.
      </p>
      <h3 className="PRV-ParaHeader">Acceptance of this policy</h3>
      <p className="PRV-Paragraph">
        You acknowledge that you have read this Policy and agree to all its
        terms and conditions. By accessing and using the Website and Services
        and submitting your information you agree to be bound by this Policy. If
        you do not agree to abide by the terms of this Policy, you are not
        authorized to access or use the Website and Services.
      </p>
      <h3 className="PRV-ParaHeader">Contacting us</h3>
      <p className="PRV-Paragraph">
        If you have any questions, concerns, or complaints regarding this
        Policy, the information we hold about you, or if you wish to exercise
        your rights, we encourage you to contact us using the details below:
      </p>
      <p className="PRV-Paragraph-LINK">
        <a
          href="&#109;&#097;&#105;&#108;&#116;&#111;&#058;st&#101;&#118;&#101;.&#107;u&#114;ows&#107;i&#64;&#103;m&#97;&#105;&#108;&#46;&#99;o&#109;"
          className="PRV-LINK"
          target="_blank"
          rel="noreferrer"
          
        >
          &#115;t&#101;&#118;&#101;&#46;&#107;ur&#111;w&#115;&#107;i&#64;&#103;ma&#105;l&#46;co&#109;
        </a>
      </p>
      <p className="PRV-Paragraph">
        We will attempt to resolve complaints and disputes and make every
        reasonable effort to honor your wish to exercise your rights as quickly
        as possible and in any event, within the timescales provided by
        applicable data protection laws.
      </p>
      <p className="PRV-Paragraph">
        This document was last updated on January 13, 2023.
      </p>
      <p className="PRV-Paragraph"></p>
      <div>
        <h3 className="PRV-ParaHeader">Contact Us</h3>
      </div>
      <div>
        <p>
          If you have any questions about these Terms and Conditions, You can
          contact us:
        </p>
        <ul>
          <li>By sending us an email: (steve.kurowski@gmail.com)</li>
        </ul>
      </div>
      <nav className="logout-nav">
        {/* Hamburger Menu Icon */}
        <IconButton className="menu-icon" onClick={toggleDrawer(true)}>
          <MenuIcon
            style={{
              boxShadow: "0 0 50px #000000, 0 0 20px #ffffff",
              // Add additional styles if needed
            }}
          />
        </IconButton>

        {/* Drawer for Mobile View */}
        <Drawer
          anchor="left"
          open={isOpen}
          onClose={toggleDrawer(false)}
          className="custom-drawer"
        >
          {list()}
        </Drawer>
      </nav>
      <div className="PRV-links-section">
        <button
          className="PRV-link-button"
          onClick={() => navigateTo("/about")}
        >
          About
        </button>
        <button
          className="PRV-link-button"
          onClick={() => navigateTo("/careers")}
        >
          Careers
        </button>
        <button
          className="PRV-link-button"
          onClick={() => navigateTo("/tokens")}
        >
          Tokens
        </button>
        <button
          className="PRV-link-button"
          onClick={() => navigateTo("/contactus")}
        >
          Contact Us
        </button>
        <button
          className="PRV-link-button"
          onClick={() => navigateTo("/login")}
        >
          Login
        </button>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
