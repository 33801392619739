import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getCookieConsentValue } from "react-cookie-consent";

import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_GOOGLE_CLOUD_API_KEY1,
  authDomain: process.env.REACT_APP_GOOGLE_CLOUD_API_KEY1authDomain,
  projectId: process.env.REACT_APP_GOOGLE_CLOUD_API_KEY1projectId,
  storageBucket: process.env.REACT_APP_GOOGLE_CLOUD_API_KEY1storageBucket,
  messagingSenderId: process.env.REACT_APP_GOOGLE_CLOUD_API_KEY1messagingSenderId,
  appId: process.env.REACT_APP_GOOGLE_CLOUD_API_KEY1appId,
  measurementId: process.env.REACT_APP_GOOGLE_CLOUD_API_KEY1measurementId,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app); 
export { db };
// eslint-disable-next-line no-unused-vars
if (getCookieConsentValue() === "true") {
  getAnalytics(app);
}
const auth = getAuth(app);
export {
  auth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
};

// Function to log out the user
export const logout = async () => {
  try {
    await signOut(auth);
  } catch (error) {
    console.error("Logout error:", error);
  }
};
