import React, { useState, useEffect, useRef } from "react";
import "./VoiceBotToolsIFrame.css";
import { getAuth, getIdToken } from "firebase/auth";

const VoiceBotToolsIframe = () => {
  const iframeRef = useRef(null);
  const [iframeSrc, setIframeSrc] = useState("");
  //const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    if (auth.currentUser) {
      getIdToken(auth.currentUser)
        .then((firebaseAuthToken) => {
          const serverEndpoint = `https://voicebot.ispeakwell.ca/fluency-html?authToken=${firebaseAuthToken}`;
          setIframeSrc(serverEndpoint);
        })
        .catch((error) => {
          console.error("Error getting ID token:", error);
        });
    }
  }, []);

  const handleUserInteraction = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage('start-audio-context', '*');
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleUserInteraction);

    return () => {
      document.removeEventListener('click', handleUserInteraction);
    };
  }, []);

  return (
    <div id="wrapTools">
      <div id="VoiceBotToolsSetting">
        <iframe
          ref={iframeRef}
          id="theBotTools"
          src={iframeSrc}
          title="VoiceBotTools"
          allow="microphone"
          onError={() => console.error("Failed to load iframe content")}
        ></iframe>
      </div>
    </div>
  );
};

export default VoiceBotToolsIframe;
