import React, { useState, useEffect, useRef } from 'react';
import './InputForm.css';
import { db } from "../utils/firebase";
import { doc, updateDoc, deleteDoc, collection, addDoc, query, where, getDocs } from "firebase/firestore";
import { getAuth } from "firebase/auth";


const InputRow = ({ index, data, onDataChange, onRemove, onSave, playerRef, videoId, handlePlay, handlePause, handleStop, handleRepeat500ms, handleRepeatCycle, handleRepeat2s, }) => {
  const [typingTimeout, setTypingTimeout] = useState(null); // To handle debouncing

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    // If you only want to debounce for specific fields, add a condition here
    if (name !== 'text') {
      // Cancel the previous timeout if there's a new keypress
      if (typingTimeout) clearTimeout(typingTimeout);
  
      // Save the timeout ID so it can be cleared if needed
      const newTypingTimeout = setTimeout(() => {
        let updatedValue = value;
  
        // If it's a videoId field, extract the video ID from the URL after a delay
        if (name === 'videoId') {
          videoId = extractVideoId(value); // Extract the video ID from the URL
          if (videoId) {
            updatedValue = videoId; // If it's valid, set the video ID as the input value
          }
        }
  
        // Update the state with the processed value
        onDataChange(index, { ...data, [name]: updatedValue });
  
      }, 7000); // Wait 7000ms after the last keystroke to process
  
      // Update state immediately with the current value of the input
      setTypingTimeout(newTypingTimeout);
    } else {
      // Directly update the text field without debouncing
      onDataChange(index, { ...data, [name]: value });
    }
  };
    
  const extractVideoId = (url) => {
    console.log('Extracting video ID from URL:', url);
    const regex = /(?:youtube\.com(?:\/[^/]+\/[^/]+|\/(?:[^/?]+)\/?[^/]*\?v=|\/watch\?v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    console.log('Matched video ID:', match);
    
    // Return video ID if matched, otherwise return empty string
    return match ? match[1] : '';
  };  

  const handleTimeChange = (e, field) => {
    const { value } = e.target;
    
    // Update the value of the corresponding field
    const updatedData = { ...data, [field]: value };
  
    // If updating the end time, check if it is less than the start time
    if (field === 'stopTime' && data.startTime && value) {
      const startTimeParts = data.startTime.split(':');
      const stopTimeParts = value.split(':');
  
      // Convert both times to seconds to compare them
      const startTimeInSeconds = (parseInt(startTimeParts[0], 10) * 3600) + (parseInt(startTimeParts[1], 10) * 60) + parseInt(startTimeParts[2], 10);
      const stopTimeInSeconds = (parseInt(stopTimeParts[0], 10) * 3600) + (parseInt(stopTimeParts[1], 10) * 60) + parseInt(stopTimeParts[2], 10);
  
      // If stop time is smaller than start time, swap them
      if (stopTimeInSeconds < startTimeInSeconds) {
        updatedData.stopTime = data.startTime;  // Swap End Time with Start Time
        updatedData.startTime = value;  // Set Start Time to the new End Time value
      }
    }
    
    // Apply the updated data
    onDataChange(index, updatedData);
  };
  

  const handleTimeBlur = (e, field) => {
    const { value } = e.target;
    const regex = /^([0-1]?[0-9]|2[0-3]):([0-5]?[0-9]):([0-5]?[0-9])$/; // hh:mm:ss format

    if (value !== '' && !regex.test(value)) {
      alert('Invalid time format. Please use hh:mm:ss');
    } else {
      onDataChange(index, { ...data, [field]: value });
    }
  };

  const getCurrentTime = (field) => {
    if (playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime(); // Get current time from the player
      const formattedTime = formatTime(currentTime); // Format the time as hh:mm:ss
  
      // If the Video ID is empty, populate it with the player's video ID
      if (!data.videoId) {
        const videoData = playerRef.current.getVideoData ? playerRef.current.getVideoData() : null;
        
        if (videoData && videoData.video_id) {
          const videoId = videoData.video_id; // Extract video ID from the player
          onDataChange(index, { ...data, videoId }); // Update the videoId field
        }
      }
  
      const updatedData = { ...data, [field]: formattedTime };
  
      // Check if we need to swap the times when the button is clicked
      if (field === 'startTime' && updatedData.startTime && data.stopTime) {
        const startTimeParts = updatedData.startTime.split(':');
        const stopTimeParts = data.stopTime.split(':');
        
        // Convert both times to seconds to compare them
        const startTimeInSeconds = (parseInt(startTimeParts[0], 10) * 3600) + (parseInt(startTimeParts[1], 10) * 60) + parseInt(startTimeParts[2], 10);
        const stopTimeInSeconds = (parseInt(stopTimeParts[0], 10) * 3600) + (parseInt(stopTimeParts[1], 10) * 60) + parseInt(stopTimeParts[2], 10);
  
        // If the start time is larger than the stop time, swap them
        if (startTimeInSeconds > stopTimeInSeconds) {
          updatedData.startTime = data.stopTime;
          updatedData.stopTime = formattedTime;
        }
      } else if (field === 'stopTime' && updatedData.stopTime && data.startTime) {
        const startTimeParts = data.startTime.split(':');
        const stopTimeParts = updatedData.stopTime.split(':');
        
        // Convert both times to seconds to compare them
        const startTimeInSeconds = (parseInt(startTimeParts[0], 10) * 3600) + (parseInt(startTimeParts[1], 10) * 60) + parseInt(startTimeParts[2], 10);
        const stopTimeInSeconds = (parseInt(stopTimeParts[0], 10) * 3600) + (parseInt(stopTimeParts[1], 10) * 60) + parseInt(stopTimeParts[2], 10);
  
        // If the stop time is smaller than the start time, swap them
        if (stopTimeInSeconds < startTimeInSeconds) {
          updatedData.stopTime = data.startTime;
          updatedData.startTime = formattedTime;
        }
      }
  
      // Apply the updated data
      onDataChange(index, updatedData);
    }
  };
  

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };
  
  return (
    <div className="row-container">
      {/* Row number display */}
      <div className="row-number">
        Task {index + 1} {/* +1 to start counting from 1 */}
      </div>

      {/* Video ID Section */}
      <div className="input-group">
        <label className="label" htmlFor={`videoId-${index}`}>Video ID</label>
        <input
          id={`videoId-${index}`}
          type="text"
          name="videoId"
          value={data.videoId}
          onChange={handleChange}
          placeholder="Video ID"
          className="IF-input"
        />
      </div>

      {/* Time Section (Start and Stop Time) */}
      <div className="input-group">
        <div className="input-group-item">
          <label className="label" htmlFor={`startTime-${index}`}>Start Time</label>
          <div className="input-container">
            <input
              id={`startTime-${index}`}
              type="text"
              name="startTime"
              value={data.startTime}
              onChange={(e) => handleTimeChange(e, 'startTime')}
              onBlur={(e) => handleTimeBlur(e, 'startTime')}
              placeholder="hh:mm:ss"
              className="IF-input small-input"
            />
            <button
              type="button"
              onClick={() => getCurrentTime('startTime')}
              className="capture-time-button"
            >
              get
            </button>
          </div>
        </div>

        <div className="input-group-item">
          <label className="label" htmlFor={`stopTime-${index}`}>Stop Time</label>
          <div className="input-container">
            <input
              id={`stopTime-${index}`}
              type="text"
              name="stopTime"
              value={data.stopTime}
              onChange={(e) => handleTimeChange(e, 'stopTime')}
              onBlur={(e) => handleTimeBlur(e, 'stopTime')}
              placeholder="hh:mm:ss"
              className="IF-input small-input"
            />
            <button
              type="button"
              onClick={() => getCurrentTime('stopTime')}
              className="capture-time-button"
            >
              get
            </button>
          </div>
        </div>
        
      </div>

      {/* Text Section */}
      <div className="input-group">
        <label className="label" htmlFor={`text-${index}`}>Text</label>
        <textarea
          id={`text-${index}`}
          name="text"
          value={data.text}
          onChange={handleChange}
          placeholder="Text"
          className="IF-input text-field"
        />
      </div>

      {/* Repeat Count Section */}
      <div className="input-group">
        <label className="label" htmlFor={`repeatCount-${index}`}>Repeat Count</label>
        <select
          id={`repeatCount-${index}`}
          name="repeatCount"
          value={data.repeatCount}
          onChange={handleChange}
          className="IF-input"
        >
          {[1, 2, 3, 4, 5, 10].map((count) => (
            <option key={count} value={count}>{count}</option>
          ))}
        </select>
      </div>

      {/* Level Section */}
      <div className="input-group">
        <label className="label" htmlFor={`level-${index}`}>Level</label>
        <select
          id={`level-${index}`}
          name="level"
          value={data.level}
          onChange={handleChange}
          className="IF-input"
        >
          {['A1', 'A2', 'B1', 'B2', 'C1', 'C2', 'M1', 'M2'].map((level) => (
            <option key={level} value={level}>{level}</option>
          ))}
        </select>
      </div>

      {/* Focus Section */}
      <div className="input-group">
        <label className="label" htmlFor={`focus-${index}`}>Focus</label>
        <select
          id={`focus-${index}`}
          name="focus"
          value={data.focus}
          onChange={handleChange}
          className="IF-input"
        >
          {['Begin', 'Fluency', 'Vocabulary', 'Grammar', 'Talk', 'Idiom', 'Persuasion', 'Culture', 'Text', 'Logic', 'End'].map((focus) => (
            <option key={focus} value={focus}>{focus}</option>
          ))}
        </select>
      </div>
      <div className="vidControls">
          <button onClick={handlePlay}>Play</button>
          <button onClick={handlePause}>Pause</button>
          <button onClick={handleStop}>Stop</button>
          <button onClick={handleRepeat500ms}>Repeat 500ms</button>
          <button onClick={handleRepeatCycle}>Repeat Trial Cycle</button>
          <button onClick={handleRepeat2s}>Repeat 2s</button>
      </div>
      {/* Remove Button */}
      <button onClick={() => onRemove(index)} className="remove-button">
        Remove Item
      </button>

      <button onClick={() => onSave(index)} className="save-button">
        Save Item
      </button>

    </div>
  );
};

const InputForm = ({ formData, onUpdate, playerRef, videoId, handlePlay, handlePause, handleStop, handleRepeat500ms, handleRepeatCycle, handleRepeat2s}) => {
  const [rows, setRows] = useState([
    { videoId: videoId, startTime: '00:00:00', stopTime: '', text: '', repeatCount: 1, level: 'A1', focus: 'Begin'},
  ]);
  
  const [firstRowSaved, setFirstRowSaved] = useState(false); // Flag to track if the first row is saved
  const hasSavedFirstRow = useRef(false); // Ref to prevent double saving

  useEffect(() => {
    const fetchVideoData = async () => {
        if (!videoId) return; // Prevent querying Firestore if no videoId

        try {
            const q = query(collection(db, "videoSegments"), where("videoId", "==", videoId));
            const querySnapshot = await getDocs(q);
            const fetchedData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

            // Update rows with fetched data from Firestore
            setRows(fetchedData);
        } catch (error) {
            console.error("Error fetching video data: ", error);
        }
    };

    fetchVideoData();
}, [videoId]);

  // Effect to save the first row to Firebase when the component mounts
  useEffect(() => {
    const saveFirstRowToFirebase = async () => {
      // Check if rows exist and firstRowSaved is false and if the first save hasn't been triggered before
      if (rows.length > 0 && !firstRowSaved && !hasSavedFirstRow.current) {
        hasSavedFirstRow.current = true; // Prevent double saving

        const auth = getAuth();
        const user = auth.currentUser;

        if (!user) {
          console.error("User is not authenticated");
          return;
        }

        const newRow = rows[0]; // First row (row 0)
        try {
          // Save the first row to Firestore immediately
          const docRef = await addDoc(collection(db, "users", user.uid, "tasks"), newRow);
          console.log("First task added with ID: ", docRef.id);

          // Now that the first row is saved, we can update the state with the generated ID
          const updatedRow = { ...newRow, id: docRef.id };
          const updatedRows = [updatedRow, ...rows.slice(1)]; // Keep the remaining rows (if any)
          setRows(updatedRows);
          setFirstRowSaved(true); // Mark the first row as saved

          // Call the onUpdate function to propagate the updated rows
          onUpdate(updatedRows);
        } catch (error) {
          console.error("Error saving first task:", error);
        }
      }
    };

    saveFirstRowToFirebase(); // Call the function to save the first row
  }, [rows, firstRowSaved, onUpdate]); // Dependencies updated

  // Effect to update rows when formData is received
  useEffect(() => {
    if (formData && formData.length > 0) {
      setRows(formData);  // Set the rows to formData
    }
  }, [formData]);  // Only update when formData changes

  const handleAddRow = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
  
    if (!user) {
      console.error("User is not authenticated");
      return;
    }
  
    const lastRow = rows[rows.length - 1];
  
    const newRow = {
      videoId: lastRow.videoId,
      startTime: lastRow.stopTime,
      stopTime: '',
      text: '',
      repeatCount: lastRow.repeatCount,
      level: lastRow.level,
      focus: lastRow.focus,
      userId: user.uid,
    };
  
    try {
      // Save the new task to Firestore
      const docRef = await addDoc(collection(db, "users", user.uid, "tasks"), newRow);
      console.log("Task added with ID: ", docRef.id);
  
      // Update the local state with the new row
      const updatedRow = { ...newRow, id: docRef.id };
      const updatedRows = [...rows, updatedRow];
      setRows(updatedRows);
      onUpdate(updatedRows);
  
      // Delete the original row from the initial directory
      const originalRow = lastRow;
      if (originalRow.id) {
        await deleteDoc(doc(db, "users", user.uid, "tasks", originalRow.id));  // Delete from Firestore
        console.log("Original task deleted from initial directory:", originalRow.id);
      }
  
    } catch (error) {
      console.error("Error saving task:", error);
    }
  };
  
  const handleRemoveRow = async (index) => {
    const auth = getAuth();
    const user = auth.currentUser;
  
    if (!user) {
      console.error("User is not authenticated");
      return;
    }
  
    const rowToDelete = rows[index];
  
    try {
      // Delete the task from Firestore
      await deleteDoc(doc(db, "users", user.uid, "tasks", rowToDelete.id));
  
      // Update the local state by removing the task
      const updatedRows = rows.filter((_, i) => i !== index);
      setRows(updatedRows);
  
      // Optionally notify the parent component of the changes
      onUpdate(updatedRows);
    } catch (error) {
      console.error('Error removing task:', error);
    }
  };
  
  

  // Update a row in Firestore
  const handleDataChange = async (index, updatedData) => {
    const auth = getAuth();
    const user = auth.currentUser;
  
    if (!user) {
      console.error("User is not authenticated");
      return;
    }
  
    // Ensure userId is retained even after update
    const updatedRow = { ...updatedData, userId: user.uid };
  
    // Update local state
    const updatedRows = rows.map((row, i) => (i === index ? updatedRow : row));
    setRows(updatedRows);
  
    // If the row already has an ID, update it in Firestore
    if (updatedData.id) {
      try {
        // Update the task in Firestore
        await updateDoc(doc(db, "users", user.uid, "tasks", updatedData.id), updatedRow);
  
        // Optionally notify the parent component of the changes (if required)
        onUpdate(updatedRows);
      } catch (error) {
        console.error('Error updating task:', error);
      }
    } else {
      console.error("No document ID found for this row");
    }
  };
  
  
  

  return (
    <div className="scrollable-container">
      {rows.map((row, index) => (
        <InputRow
          key={index}
          index={index}
          data={row}
          onDataChange={handleDataChange}
          onRemove={handleRemoveRow}
          onSave={handleDataChange}
          playerRef={playerRef} // Pass playerRef to InputRow
          handlePlay={handlePlay}
          handlePause={handlePause}
          handleStop={handleStop}
          handleRepeat500ms={handleRepeat500ms}
          handleRepeatCycle={handleRepeatCycle}
          handleRepeat2s={handleRepeat2s}
        />
      ))}
      <button onClick={handleAddRow} className="add-button">
        New Task
      </button>
    </div>
  );
};

export default InputForm;
