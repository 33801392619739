import React, { useState, useEffect, useRef } from 'react';
import './Overlay.css';  // Import the CSS for styling the grid
import Timer from './timer';  // Import the Timer component

const Overlay = ({
  username,
  visits,
  totalTimeOnSite,
  phonemesPerMinute,
  wordsPerMinute,
  totalWordsAcquired,
  newWordsAcquired,
  twoPhonemeCombinations,
  vocab,
  grammar,
  idiom,
  persuasion,
  logic,
  culture,
  fluency,
  talk,
  progress,
  testsCompleted,
  aggregatedScore,
  lastInteraction
}) => {
  const [timeOnSite, setTimeOnSite] = useState(0); // Managing timeOnSite state locally

  // Function to update timeOnSite from Timer component
  const handleTimeUpdate = (newTime) => {
    setTimeOnSite(newTime);
  };

  const combinedTimeOnSite = totalTimeOnSite + timeOnSite;

  const [interactions, setInteractions] = useState([]);
  const listRef = useRef(null); // Create a ref for the scrollable container

  // Function to update the last interaction
  useEffect(() => {
    if (lastInteraction) {
      // Ensure lastInteraction is an array (if it's not, treat it as a single item)
      const newInteractions = Array.isArray(lastInteraction) ? lastInteraction : [lastInteraction];
      console.log("lastInteraction: ", lastInteraction);
      
      // Only append the new interaction if it's different from the last one
      setInteractions(prevInteractions => {
        // Create a new list with the latest interaction appended
        const updatedInteractions = [...prevInteractions, ...newInteractions];
        
        // Ensure only the last 200 interactions are kept
        if (updatedInteractions.length > 200) {
          updatedInteractions.shift(); // Remove the oldest interaction (first element)
        }

        return updatedInteractions;
      });
    }
  }, [lastInteraction]);

  // Scroll to the bottom of the list whenever interactions change
  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight; // Scroll to bottom
    }
  }, [interactions]); // This effect runs when interactions change


  return (
    <div className="overlay-container">
      <div className="grid-item grid-header grid-item-two-span">
        <h2>User Statistics</h2>
      </div>
  
      <div className="grid-item group-title">
        <h2>User</h2>
      </div>
      
      <div className="grid-item grid-item-two-span">
        <h3>{username}</h3>
      </div>
  
      <div className="grid-item group-title">
        <h2>Engagement</h2>
      </div>
  
      <div className="grid-item">
        <h2>Current Platform Presence</h2>
        <h4><Timer onTimeUpdate={handleTimeUpdate} /></h4> {/* Automatically updates */}
      </div>
      <div className="grid-item">
        <h2>Visits</h2>
        <h4>{visits}</h4>
      </div>
      <div className="grid-item">
        <h2>Total Platform Presence</h2>
        <h4>{formatTime(combinedTimeOnSite)}</h4>
      </div>
  
      <div className="grid-item group-title">
        <h2>Words & Phonemes</h2>
      </div>
      <div className="grid-item">
        <h2>Phonemes per minute</h2>
        <h4>{phonemesPerMinute}</h4>
      </div>
      <div className="grid-item">
        <h2>Words per minute</h2>
        <h4>{wordsPerMinute}</h4>
      </div>
      <div className="grid-item">
        <h2>Total words acquired</h2>
        <h4>{totalWordsAcquired}</h4>
      </div>
      <div className="grid-item">
        <h2>New words acquired</h2>
        <h4>{newWordsAcquired}</h4>
      </div>
      <div className="grid-item">
        <h2>2-phoneme combinations</h2>
        <h4>{twoPhonemeCombinations}</h4>
      </div>
  
      <div className="grid-item group-title">
        <h2>Skills</h2>
      </div>
      <div className="grid-item">
        <h2>Vocabulary</h2>
        <h4>{vocab}</h4>
      </div>
      <div className="grid-item">
        <h2>Grammar</h2>
        <h4>{grammar}</h4>
      </div>
      <div className="grid-item">
        <h2>Idiom</h2>
        <h4>{idiom}</h4>
      </div>
      <div className="grid-item">
        <h2>Persuasion</h2>
        <h4>{persuasion}</h4>
      </div>
      <div className="grid-item">
        <h2>Logic</h2>
        <h4>{logic}</h4>
      </div>
      <div className="grid-item">
        <h2>Culture</h2>
        <h4>{culture}</h4>
      </div>
      <div className="grid-item">
        <h2>Fluency</h2>
        <h4>{fluency}</h4>
      </div>
      <div className="grid-item">
        <h2>Talk</h2>
        <h4>{talk}</h4>
      </div>
  
      <div className="grid-item group-title">
        <h2>Progress & Testing</h2>
      </div>
      <div className="grid-item">
        <h2>Progress</h2>
        <h4>{progress}</h4>
      </div>
      <div className="grid-item">
        <h2>Tests completed</h2>
        <h4>{testsCompleted}</h4>
      </div>
      <div className="grid-item">
        <h2>Aggregated score</h2>
        <h4>{aggregatedScore}</h4>
      </div>
  
      <div className="grid-item group-title">
        <h2>Interactions</h2>
        <ul className="interaction-list" ref={listRef}>
          {interactions.map((interaction, index) => (
            <li key={index} className="interaction-item">{interaction}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 3600); // 1 hour = 3600 seconds
  const minutes = Math.floor((seconds % 3600) / 60); // Remaining minutes
  const sec = seconds % 60; // Remaining seconds
  if (hours === 0){
    return `${String(minutes).padStart(2, '0')}:${String(sec).padStart(2, '0')}`;
  } else{
  // Return in HH:MM:SS format, ensuring leading zeroes where necessary
   return `${String(hours)}:${String(minutes).padStart(2, '0')}:${String(sec).padStart(2, '0')}`;
  }
};


export default Overlay;
